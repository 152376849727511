import React from "react";

import SEO from "components/utils/SEO";
import Container from "components/atoms/Container";
import NotFoundView from "components/views/NotFound";

const NotFoundPage = () => (
  <Container top="sm" right="lg" left="lg">
    <SEO title="Page not found" description="Page not found" keywords={["404", "Not found"]} />

    <NotFoundView />
  </Container>
);

export default NotFoundPage;
