import React from "react";

import { Svg } from "./styles";
import "./styles.css";

const NotFoundView = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 700 780" preserveAspectRatio="none">
    <path
      className="r f-l s-b w-10"
      d="M132 739.3C128.2 704.6 143.4 613.5 106.8 628.7 84.8 644.5 78.2 683.4 86.1 714.5c-33 3.6-45.8 36.6-45.9 31.6 15.8 0.2 71.3-2.1 91.9-6.8z"
    />
    <path d="m391.8 198.2 30.4 57.1c40.7-1.4 32.2-74.3-30.4-57.1z" className="r f-g s-b w-8" />
    <path d="m424.4 264.8 13.8 48.5c34.8 2.8 41.9-61.5-13.8-48.5z" className="r f-g s-b w-8" />
    <path d="m442.3 326.8 9.2 39.9c34.8 2.8 46.5-52.9-9.2-39.9z" className="r f-g s-b w-8" />
    <path d="m354 148.6 34.4 31.9c42.3 5.4-5.7-82.8-34.4-31.9z" className="r f-g s-b w-8" />
    <path d="m264.2 123.9 69.6 12.6c13.2 2-7.3-30.2-18-32.8-6.6-1.6-28.7 5.7-51.6 20.2z" className="r f-g s-b w-8" />
    <path d="m197.4 134.2 49.7-9.3c13.2 2-9-16.5-19.7-19.2-10.5-2.7-15.2 11.7-30 28.5z" className="r f-g s-b w-8" />
    <path d="m471.6 553-3.5 38.5c31.8 5.6 44.9-38.2 3.5-38.5z" className="r f-g s-b w-6" />
    <path d="m477 622.3 48.6 19.2c15.9 1-8.8-20.8-13.3-26.7-6.7-9-30-3.3-35.4 7.5z" className="r f-g s-b w-6" />
    <path d="m540.9 639.6 43.5 16.9c-5.3-6-13.8-30.1-18.7-30-5.3 3.5-19.5 8.3-24.8 13.1z" className="r f-g s-b w-4" />
    <path d="m587.6 657.1 46.8 10.3c-5.3-6-26-31-30.9-30.9-5.3 3.5-10.6 15.8-15.9 20.6z" className="r f-g s-b w-4" />
    <path d="m653.8 675.3 43.5 7.5c-9-22.7-23.6-32.5-43.5-7.5z" className="r f-g s-b w-4" />
    <path
      className="s-b w-2"
      d="m329.6 459.8c-18.7-22.3-40.4 6.2-56.5-12.6 2.3 22.5-17 21.6-24.6 6.3l-14.6 28.9c41.8 2.4 64.9-13.9 95.6-22.6z"
    />
    <path
      className="r f-l s-b w-10"
      d="m80.1 327.3c-5.9-46.7 8.6-85.4 31.8-116.1 23.1-26.6 51.2-54.2 80.4-69.4 72.1-37.1 162.9-9.3 213.1 60.2 40 112.1 76.4 240.6 16.1 289.2 28.5-9.4 29.6-77.4 81.2-98.6 25.8 31.1 14.6 83.2-50.7 148.4 14.3 10.9 26.5 26.6 23.5 77l-12.2 9.4 31 2.8 217 64.8c-88.4 21.7-270.9 77-282.7 12.2l4.7 17.4 5.6 14.1-111.3 0.5c8.9-17.2 4.8-33.8 25.8-34.8 2.8-100.1 37.4-74.5 48.4-90.2 0 0-29.9-2.5-46.5 48.8l-6.6 34.8c-9.6 9.1-21 7.5-27.2 37.1l-209.9 4.7c0.9-57.7 15.5-70.7 5.1-180.7-3.2-34.3-2.7-66.1-5.9-95.7-5.6-51.2-16.2-95.9-30.7-136z"
    />
    <path
      className="s-b w-2"
      d="m135 349.7c17.1 42.7 30.1 85.4 25.6 134.8 65.7 7.3 146.1 2.1 216.5-35.5 8.5 7.3 12.6 3.3 15.5-3.9 2-4.8 3.8-11.7 4.8-15.5 13.2-51.7-7.6-121.1-27.6-145.6-207.9 25-188.6 37.3-233.5 66.1z"
    />
    <path
      className="fs-r w-10"
      d="m383.7 357.2-0.6 63.5c-27.6 41.7-77.3 49.1-126.4 54.9 4.6-44.1-25.9-108 127-118.5z"
    />
    <path
      className="f-w s-b w-4"
      d="m147.2 342.6c41.2-19.3 73.6-41.9 160.7-46.9-6.4 13.1-2.3 26.6-8.6 34.6-4.5-3.6-7.6-7.9-10.2-12.4-2.8-4.4-9.1-6.2-10.7-3.4-2.2 4 0.4 14.3-3.3 15.1-25.9-30.3-32.2-0.7-33 16.5-15.9-7.9-18.2-14.7-16.9-21.2 0 0-18.7-3.5-16 21.1-6.1 0.7-12.1 1.1-15.5-7.3-12.7-16.2-29.5 22.3-24.8 38.3-21.4 0.2-20.7-22.2-21.7-34.4z"
    />
    <path d="m116.5 310.2c-2.8-19.4 18.4-76.5 54.8-25.2 17.6 22.8-22.3 52.9-54.8 25.2z" className="r s-b" />
    <path d="m142 293.6c31.7 0.8 7.4-24.2 0.8-13.5-1.2 2.2-0.8 6.4-0.8 13.5z" className="r f-w s-w" />
    <path
      className="s-b w-2"
      d="m331.4 192.9c-11.4-15.3-29.9-22-48.5-15.5-8.4 3-15.1 9.2-20.8 17.5-6.5 9.7-9 21.4-7.8 32.8 3.5 24.8 22.1 40.1 45.6 40.6 17-1.4 33.5-14.5 37.5-40.3 1.2-13.1-1.3-26.6-6-35.1z"
    />
    <path
      className="r f-w s-w w-2"
      d="m295.4 210.7c16.5 2.2 19.3-18.9 14.1-24.5-4.9-3.2-13.5-1.7-18.3 3.3-6.3 6-5.1 13-1.8 16.6 5.6-1.6 7.5-0.1 6 4.6z"
    />
    <path d="m337.8 178.5c-20.3-31.1-60.1-29.6-72.3-14.1" className="f-n s-b w-4" />
    <path
      className="f-w s-b w-8"
      d="m360.7 284.8c4 34.3-2.5 73.3-20.6 83.7-12.8-28.9-20.5-57.6-49.5-70.4 22.2-14.1 46.1-14.2 70.1-13.4z"
    />
    <path
      className="r s-b w-4"
      d="M127 356.4C193.5 300.3 276.9 279 371.8 281.3l-2.7 4.6c-81.8 3.9-162.1 23.3-242.1 70.4z"
    />
    <path
      className="w-2"
      d="m370.7 283.6c32.1 95.3 30.7 123.8 18.6 161.3-0.1-5.6-5 1.5-5-4-0.2-53.2-5.4-104.7-22-152.6z"
    />
    <path d="m194.1 486.6c-2.8-12.7-12.8-23.6-21.2-33.1 1.5 22-10.3 20.5-13.7 31.6z" className="r f-w s-b w-4" />
    <path
      className="r s-b f-w w-2"
      d="m234.2 482.8c-7-3.7-11.6-7.5-10.5-10.6 0.8-2.5-0.4-4.7-2.3-6.9-8.6-14.4-14.4 13.8-14.5 21.6z"
    />
    <path d="m265.9 477.1c-6.5-5.4-10.6-12.3-15.5-18.1-15.4 8.3-9.7 15.7-9.5 22.5z" className="r s-b f-w w-2" />
    <path d="m159.5 484.2c83.6 3.4 139.7 7.7 220.7-34.9l-2-7.2c-72 39-125.9 40.9-218.7 42.1z" className="r s-b w-4" />
    <path d="m375.6 451.8c-11.3-63.2-33.3-53.1-33.1 7.7 6.2-4.6 26.8-7.3 33.1-7.7z" className="r f-w s-b w-6" />
    <path d="m351.2 463.5c-30-17.5-35.1-2-72.4-17.9l-9.3 38.5z" className="s-b w-2" />
    <path
      className="r s-b f-w w-2"
      d="m294.2 474c1.1-8.3-8.2-11.3-13.1-17.1-15.4 8.3-13.7 12.8-13.5 19.6 6.2-4.6 20.4-2.1 26.6-2.4z"
    />
    <path
      className="r f-g s-b w-8"
      d="m320.2 726.2-9.9 0.6-115.4 6.6c-3.8-43.4-20.5-73.8-15.5-103 12.6-72.8 106.5-89.3 138.2-20.7 11 23.7 12.9 57.5 13.2 95 0 5.9-16.7 11.2-13.9 20.4z"
    />
    <path
      className="r f-l s-b w-10"
      d="m114.7 526.9-2.4-12.9c-0.7-48.5-6.6 28.3-1.3-60.7 2.5-42.3-37-68.8-82.8-62-7.4 64.4 97.3 98.7 86.6 135.6z"
    />
  </Svg>
);

export default NotFoundView;
